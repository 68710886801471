.about {
    margin: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.facepic {
    width: 30%;
}

.abouttext {
    width: 30%;
}

.links {
    display: flex;
    flex-direction: row;
}

.abouttext a {
    padding-right: 10px;
    font-size: 30px;
    color: black;
}

.aboutSubText {
    font-size: 20px;
}

@media only screen and (max-width: 600px) {
    .about {
        margin: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .facepic {
        width: 80%;
    }
    
    .abouttext {
        width: 80%;
    }
}