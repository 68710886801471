.work {
    margin: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.small {
    width: 20%;
}

.wide {
    width: 55%;
}

@media only screen and (max-width: 600px) {
    .work {
        margin: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    
    .small {
        margin: 25px 0;
        width: 80%;
    }
    
    .wide {
        width: 80%;
    }
}