@import url(./styling/navigation.scss);

// home
@import url(./styling/home.scss);

// works
@import url(./styling/works.scss);

// about
@import url(./styling/about.scss);


.pageContent{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

:root {
  --mainBlue: #408cff;
  --white: #ffffff;
}