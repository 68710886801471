nav{
  background-color: var(--mainBlue);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navUrl{
  color: var(--white);
  padding: 15px 10px;
  justify-content: center;
}