.homeParallax {
  // Background image
  background-image: url("https://wallup.net/wp-content/uploads/2017/11/23/533438-board_games-terraforming_mars.jpg");
  min-height: 500px; 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  // text
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeParallax .parallaxText {
  color: white;
  font-size: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  margin: 0;
}

.homeParallax .parallaxSubText {
  color: white;
  font-size: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  margin: 0;
}